<template>
    <div>
        <v-app style="background-color: #f5f5f5">
            <nav class="mt-6">
                <v-app-bar flat
                           app
                           tile
                           color="primary"
                           hide-on-scroll
                           scroll-threshold="100"
                           elevation="1">
                    <v-app-bar-nav-icon @click="drawer = !drawer"
                                        class="white--text"></v-app-bar-nav-icon>
                    <v-col cols="auto" class="white--text display-1 font-weight-bold">
                        KPORTAL
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col cols="auto">
                        <span class="font-weight-bold primary--text text--lighten-2 caption">{{GetUserUniqueId}}</span>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col cols="auto">
                        <span class="font-weight-bold white--text text--darken-2 caption">{{timeNow}}</span>
                        <span class="font-weight-bold white--text caption"> / </span>
                        <span class="font-weight-bold white--text caption text-decoration-underline" style="cursor:pointer" @click="ChangeLanguage()">{{ $t("AL_ChangeLanguage") }}</span>
                    </v-col>
                    <v-divider vertical inset></v-divider>
                    <v-col cols="auto" class="white--text title font-weight-bold">{{ $t("TH_SupplierPortal") }}</v-col>
                </v-app-bar>
                <v-navigation-drawer app v-model="drawer">
                    <v-layout column align-center>
                        <v-flex class="mt-5 text-center">
                            <v-avatar size="64" color="white--text secondary lighten-2 display-1 font-weight-bold" class="mb-4">
                                {{GetUserGorevInitials}}
                            </v-avatar>
                            <p class="title my-0">
                                {{GetUserName}}
                            </p>
                            <p class="caption mb-0">
                                {{GetUserGorev}}
                            </p>
                        </v-flex>
                    </v-layout>
                    <v-divider></v-divider>
                    <accordionmenu :form="form" @menuclicked="MenuClicked($event)">
                    </accordionmenu>

                    <template v-slot:append>
                        <div class="pa-2">
                            <v-btn depressed
                                   block
                                   dark
                                   tile
                                   @click="Logout()"
                                   color="white red--text text-capitalize">
                                <span>{{ $t("AL_Logout") }}</span>
                                <v-icon right>exit_to_app</v-icon>
                            </v-btn>
                        </div>
                    </template>
                </v-navigation-drawer>
            </nav>
            <showdialog :message="messages"></showdialog>
            <terms :message="messages"></terms>
            <router-view />
        </v-app>
    </div>
</template>
<script>
    import Vue from "vue"
    import Vuex from "vuex"

    import { mapGetters } from "vuex";
    import dynoform from "@/components/DynoForm.vue";
    import grid from "@/components/grid.vue";
    import leftmenu from "@/components/leftmenu.vue";
    import kview from "@/components/view.vue";
    import axios from "axios";
    import _ from "lodash";
    import 'material-design-icons-iconfont/dist/material-design-icons.css';
    import { FormMixIn } from "@/MixIns/FormMixins";
    import store from '@/store';
    import accordionmenu from "@/components/AccordionMenu.vue";

    import showDialog from '@/components/ShowDialog.vue';

    import moment from "moment";
    import Utilities from '@/Utilities.js'

    export default {
        mixins: [FormMixIn],
        components: {
            dynoform,
            grid,
            accordionmenu,
            kview
        },
        props: {
            form: String,
            formid: Number,
            viewname: String,
            message: Object
        },
        data: function () {
            return {
                drawer: true,
                valid: false,
                lazy: true,
                username: '',
                viewName: '',
                //form: '',
                alert: false,
                alertMessage: '',
                actionResultDialog: false,
                validateError: '',
                items: [],
                timeNow: '',
                fullPage: true,
                messages: { messageType: '', messageBody: '', infoBody: '', viewType: '' }
            };
        },
        computed:
        {
            UserLogedIn() {
                return this.GetUserLoggedIn;
            },
            GetDrawer() {
                return this.drawer && this.GetUserLoggedIn;
            }
        },
        methods: {
            Logout() {
                var payload = {
                    action: 'Logout',
                    formdata: {
                        formInputs: {},
                    },
                };

                this.$store.dispatch("AccountAction", payload)
                    .then(response => {
                        this.$store.commit("SetUserLoggedIn", false);
                        var userInfo = {};

                        userInfo.AdSoyad = "";
                        userInfo.Role = "";
                        userInfo.Gorev = "";
                        userInfo.Login = 0;
                        userInfo.FirmaNo = "";
                        userInfo.UniqueId = "";

                        this.$store.commit("SetUserField", userInfo);
                        this.$router.push({ name: 'login' });
                    })

            },
            ChangeLanguage() {
                var lang = Utilities.GetCurrentLanguage();

                if (lang == 'tr') {
                    lang = 'en';
                }
                else {
                    lang = 'tr';
                }

                this.$root.loadLanguageAsync(lang);
            },
            ToggleDrawer() {
                this.drawer = !this.drawer;
            },
            UpdateTime() {
                var timeNow = moment().format("DD/MM/YYYY HH:mm");
                this.timeNow = timeNow;
            },
            TimeLoop()
            {
                setTimeout(() =>
                {
                    this.TimeLoop();
                    Utilities.log("Keep Alive");
                    this.$store.dispatch("KeepAlive")
                }, 30000);
                this.UpdateTime();
            }
        },
        mounted() {
            Utilities.log("Form MOUNTED");
            this.TimeLoop();
        }
    };</script>
